<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="bgc sidebar">
                <div class="samplelefttit">资质</div>
                <div class="sidebarmain">
                <a-tree :tree-data="node_list" :expandedKeys.sync="expandedKeys" :replaceFields="{children:'child', title:'name', key:'id' }" :show-line="showLine" :show-icon="showIcon" @select="onSelect">
                    <a-icon slot="icon" type="carry-out"/>
                </a-tree>

<!--                    {{typeof node_list[0].id}}-->
                </div>
                <div class="addbutton">
                    <div class="addbuttonbtn" @click="createClick(0)" >
                        添加分类
                    </div>
                </div>

            </div>
            <div class="wrap-container">
                <!-- 创建、编辑分类 -->
                <div class="createsmaplewrap" v-show="createfenlei">
                    <div class="createsmaple1">
                        <div class="createsmapletit">{{ do_type?'编辑':'创建' }}分类</div>
                        <div v-if="do_type" style="margin-top: 20px; text-align: left; background: #FAFAFA; padding:10px 20px;">
                            <a-button type="primary" class="ml20" @click="createNewList()">创建分类</a-button>
                            <a-button type="primary" class="ml20" @click="createNewNode()">创建资质</a-button>
                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick" style="float: right">
                                <template slot="title">
                                    确认删除该项？
                                </template>
                                <a-button type="danger" class="ml20">
                                    删除
                                </a-button>
                            </a-popconfirm>
                        </div>
                    </div>
                    <div class="mt20">
                        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" style="margin-left: 150px">
                            <a-form-model-item label="名称">
                                <a-input v-model="form.name" class="w300"/>
                            </a-form-model-item>
                            <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                                <a-button type="primary" @click="doClick">保存</a-button>
                            </a-form-item>
                        </a-form-model>
                    </div>
                </div>

                <!--创建编辑 资质-->
                <div class="createsmaplewrap" v-show="createzizhia">
                    <div class="createsmaple1">
                        <div class="createsmapletit">{{ do_type?'编辑':'创建' }}资质资料</div>
                        <div v-if="do_type" style="margin-top: 20px; text-align: right; background: #FAFAFA; padding:10px 20px; width: 1000px">
                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick">
                                <template slot="title">
                                    确认删除该项？
                                </template>
                                <a-button type="danger" class="ml20">
                                    删除
                                </a-button>
                            </a-popconfirm>
                        </div>
                    </div>
                    <div class="mt20">
                        <a-form-model :model="form1" :label-col="labelCol" :wrapper-col="wrapperCol" style="margin-left: 150px">
                            <a-form-model-item label="名称">
                                <a-input v-model="form1.name" class="w300"/>
                            </a-form-model-item>

                            <a-form-model-item label="资质分类">
                                <a-select v-model="form1.type_id" placeholder="请选择样品分类" class="w300">
                                    <a-select-option v-for="(item,key) in type_list" :key="key" :value="item.id">
                                        {{ item.name}}
                                    </a-select-option>
                                </a-select>
                                <span class="ant-form-text" style=" margin-left: 10px; margin-right: 10px" @click="getStypenew()"><a-icon type="sync"/></span>
                                <a-button type="primary" @click="showDrawer">创建资质分类</a-button>
                            </a-form-model-item>
                            <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                                <a-button type="primary" @click="doClick1()">保存</a-button>
                            </a-form-item>
                        </a-form-model>
                    </div>
                </div>
            </div>
        </div>
        <!--抽屉-->
        <createzizhifenlei :getStype="getStype" :close="onCloseDrawer" :visible="drawer_visible"></createzizhifenlei>
    </div>
</template>

<script>
    import createzizhifenlei from '../../../components/drawer/zizhiguanli-createfenlei/zizhiguanli-createfenlei.vue';//创建资质分类

    export default {
        props: [],
        components: {
            createzizhifenlei
        },
        data() {
            return {
                type_list: [],
                expandedKeys: [],
                form_word: [],
                do_type: false,
                node_list: [],
                createzizhia: false,
                createfenlei: false,
                drawer_visible: false,
                showLine: true,
                showIcon: false,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                form: {},
                options: [],
                pid: 0,
                form1: {},
            };
        },
        watch: {
            $route: {
                handler() {
                    this.Qualifyalllist()
                    this.mountedDo()
                },
                deep: true
            }
        },
        mounted() {
            this.Qualifyalllist()
            this.mountedDo()
            this.getStype(0)
        },
        methods: {
            getStype(id) {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: '6'
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.type_list = response.data.list
                            if (id === 0) {
                                this.form1.type_id = Number(this.type_list[0].id)
                            } else {
                                this.form1.type_id = id
                            }
                            this.onCloseDrawer()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            Qualifyalllist() {
                this.$sa0.post({
                    url: this.$api('Qualifyalllist'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.node_list = response.data.list;
                            console.log(response.data.list)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            createClick(pid) {
                if (Number(this.pid) === Number(pid)) return false;
                this.$router.push('/ziziguanli/index/' + pid)
            },
            mountedDo() {
                this.pid = this.$route.params.id
                if (this.pid == 0) {
                    this.form = {
                        id: '0',
                        name: '',
                        type: '0',
                        pid: this.pid,
                        remark: '',
                        type_id: '0',
                    };
                    this.form1 = {
                        id: '0',
                        name: '',
                        type: '1',
                        pid: this.pid,
                        remark: '',
                        type_id: '0',
                    };
                    this.createzizhia = false;
                    this.createfenlei = true;
                    this.do_type = false
                } else {
                    this.getItemInfo()
                }
            },

            getItemInfo() {
                this.do_type = true
                this.$sa0.post({
                    url: this.$api('Get_qualify_detail'),
                    data: {
                        id: this.pid
                    },
                }).then((response) => {
                    console.log('response-------------')
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.form_word = response.data.qualify;
                            this.expandedKeys = ((this.form_word.qualify_ids).toString()).split(',').map((item) => {
                                return Number(item)
                            })
                            this.openArr = [this.form_word.pid]
                            console.log('type-------------', this.form_word.type)
                            if (this.form_word.type == 0) {
                                this.createzizhia = false;
                                this.createfenlei = true;
                            } else {
                                this.createzizhia = true;
                                this.createfenlei = false;
                            }
                            this.form = {
                                id: this.form_word.id,
                                name: this.form_word.name,
                                pid: this.form_word.pid,
                                type: this.form_word.type,
                                remark: this.form_word.remark,
                                type_id: this.form_word.type_id,

                            }
                            this.form1 = {
                                id: this.form_word.id,
                                name: this.form_word.name,
                                pid: this.form_word.pid,
                                type: this.form_word.type,
                                remark: this.form_word.remark,
                                type_id: this.form_word.type_id,
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            doClick() {
                let data = {
                    id: this.do_type ? this.form.id : '0',
                    name: this.form.name,
                    pid: this.form.pid,
                    remark: this.form.remark,
                    type: this.form.type,
                    type_id: this.form.type_id,
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.do_type ? this.$api('Edit_qualify') : this.$api('Create_qualify'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            if (this.do_type) {
                                this.Qualifyalllist()
                                this.mountedDo()
                            } else {
                                this.$router.push('/ziziguanli/index/' + res.data.id)
                            }
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            createNewList() {
                this.form = {
                    id: '0',
                    name: '',
                    type: '0',
                    pid: this.pid,
                    remark: '',
                    type_id: '0',
                };
                this.createzizhia = false;
                this.createfenlei = true;
                this.do_type = false
            },

            delClick() {
                this.$sa0.post({
                    url: this.$api('Del_qualify'),
                    data: {
                        id: this.form_word.id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Qualifyalllist()
                            this.$router.push('/ziziguanli/index/0')
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            createNewNode() {
                this.form1 = {
                    id: '0',
                    name: '',
                    type: '1',
                    pid: this.pid,
                    remark: '',
                    type_id: Number(this.type_list[0].id),
                };
                this.createzizhia = true;
                this.createfenlei = false;
                this.do_type = false
            },

            doClick1() {
                let data = {
                    id: this.do_type ? this.form1.id : '0',
                    name: this.form1.name,
                    pid: this.form1.pid,
                    type: this.form1.type,
                    type_id: this.form1.type_id,
                    remark: this.form1.remark,
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.do_type ? this.$api('Edit_qualify') : this.$api('Create_qualify'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            if (this.do_type) {
                                this.Qualifyalllist()
                                this.mountedDo()
                            } else {
                                this.$router.push('/ziziguanli/index/' + res.data.id)
                            }
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            getStypenew() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: '6'
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.type_list = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            //添加分类
            addfenlei() {
                this.createzizhia = false;
                this.createfenlei = true;
            },
            //创建资质
            createzizhi() {
                this.createzizhia = true;
                this.createfenlei = false;
            },

            // 定义 关闭抽屉时的 函数 编辑
            onCloseDrawer() {
                this.drawer_visible = false;
            },
            // 定义 打开抽屉时的 函数 编辑
            showDrawer() {
                this.drawer_visible = true;
            },


            onChange(date, dateString) {
                console.log(date, dateString);
            },

            onSelect(selectedKeys, info) {
                if (selectedKeys.length == 0) return false;
                if (Number(this.pid) === Number(selectedKeys[0])) return false;
                this.$router.push('/ziziguanli/index/' + selectedKeys[0])
            },
            onChange1(value) {
                console.log(value);
            },
        },
    };
</script>

<style scoped>

    .wraper{ width: 100%; height: 100%; display: flex;}

    .wrap-mainer{ flex: 1;display: flex; }

    .sidebar{ width: 200px;  display: flex; flex-direction: column}
    .sidebarmain{ flex: 1; overflow-y: auto}

    .wrap-container{ flex:1; overflow-y: auto}

    .addbutton{ width: 100%; text-align: center; bottom: 0; height: 40px; background: #1890ff}
    .addbuttonbtn{ width: 100%; color: #ffffff; line-height: 40px; cursor: pointer; height: 40px}

    .bgc{ margin-right: 20px; border: 1px solid #eeeeee;}

    .samplelefttit{ background: #f9f9f9; padding: 10px; border-bottom: 1px solid #eeeeee; font-weight: bold }

    .createsmaplewrap{ width: 1000px; }

    .createsmaple{ width: 800px; display: flex; justify-content: space-between}

    .createsmapletit{ font-size: 20px; font-weight: bold}

    .mt20{ margin-top: 20px}

    .w300{ width: 200px}

    .ml20{ margin-left: 20px}



</style>
